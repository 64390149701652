import * as React from "react";
import {useStaticQuery, graphql } from "gatsby";

const ExperienceStepsDropdown = () => {

  const data = useStaticQuery
  (graphql`
query ExperienceStepsDropdownQuery {
  experiencesteps:  allAirtable (
	 filter: { table: { eq: "BlueprintsL2" } }
   ) {
	 edges {
	   node {
		 recordId
		 data {
		   Name
		   RecordID
		   BlueprintName
		 }
	   }
	 }
   }
 }
  `);
  
  
  return (
	  <>

			  {data.experiencesteps.edges.map((edge) => (
				<option value={edge.node.data.RecordID}>{edge.node.data.BlueprintName} > {edge.node.data.Name}</option>
			  ))}
	
  </>
	);
  
  
};
  
export default ExperienceStepsDropdown;